import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  randomString,
  BIG_DATE_FORMAT,
  DATE_TIME_FORMAT_WITH_SECOND,
} from "../../../components/Common/constant";
import {
  SMALL_DATE_FORMAT,
  converDateIntoLocal,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
  currencyCode,
  currencyRate,
} from "../../../components/Common/product-model";
import { ChevronRightIcon } from "@heroicons/react/outline";
import toast, { Toaster } from "react-hot-toast";
import ReturnReplace from "./return-replace";
import CancelModal from "./cancel";
import PostRating from "./post-rating";
import {
  emptyMyorders,
  fetchOrderDetails,
  isRateProductModalOpen,
  isReturnReplaceOpen,
  isCancelModalOpen,
  updateOrder,
  fetchReplaceVariant,
  makePaymentonly,
} from "../actions";
import { fetchProductByID } from "../../Home/actions";
import FlightIcon from "../../../assets/images/Flight.png";
import DeliverytoIcon from "../../../assets/images/deliverto.svg";
import BlueStar from "../../../assets/images/blue_star.svg";
import GreyStar from "../../../assets/images/grey_star.svg";
import { confirmAlert } from "react-confirm-alert";
import StarRatings from "react-star-ratings";
import ChooseVariant from "../../Product/components/choose-variant";
import SlideOver from "../../../components/Common/SlideOver";
import RaiseTicketAdd from "../../Faq/components/raiseticketadd";
import { openSliderPop } from "../../../actions";
import { isRaiseTicketModalopen } from "../../Faq/actions";

function MyOrders(props) {
  const history = useHistory();
  const {
    session,
    isLoggedIn,
    orderDetails,
    localLanguage,
    deviceSize,
    returnReplaceModal,
    localCurrency,
    rateProduct,
  } = props;
  const [productid, setProductId] = useState("");
  const [variantId, setVariantId] = useState("");
  const [selectedVariant, setSelectedVariant] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({});
  const [allVariants, setAllVariants] = useState([]);
  const [productVariants, setProductVariants] = useState([]);
  const [originate, setOriginate] = useState("");
  const [selectedType, setSelectedType] = useState();
  const [validationVar, setValidationVar] = useState(false);
  const [isChanged, setIsChanged] = useState(true);

  const [rselectedVariant, rsetSelectedVariant] = useState({});
  const [rvariantImageId, rsetVariantImageId] = useState("");
  const [rallVariants, rsetAllVariants] = useState([]);
  const [rproductVariants, rsetProductVariants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paymethod, setPaymethod] = useState("");

  let CURRENCY = currencyCode(localCurrency);
  let CURRENCY_RATE = currencyRate(localCurrency);

  const orderStatus = [
    { status: "delivered", stausText: LocaleStrings.order_delivered },
    { status: "intransit", stausText: LocaleStrings.in_transit },
    { status: "dispatched", stausText: LocaleStrings.order_dispatched },
    { status: "placed", stausText: LocaleStrings.order_Placed },
    { status: "cancelled", stausText: LocaleStrings.order_cancelled },
    { status: "returnreceived", stausText: LocaleStrings.return_received },
    { status: "returnpicked", stausText: LocaleStrings.return_picked },
    { status: "initiatepickup", stausText: LocaleStrings.initiate_pickup },
    { status: "return", stausText: LocaleStrings.return_requested },
    { status: "replace", stausText: LocaleStrings.replacement_requested },
    { status: "refund", stausText: LocaleStrings.refund_initiated },
    { status: "refundrejected", stausText: LocaleStrings.refund_rejected },
    { status: "refunded", stausText: LocaleStrings.refunded },
    { status: "reshipdelivered", stausText: LocaleStrings.reshipdelivered },
    { status: "reship", stausText: LocaleStrings.reship },
    { status: "cancelrefund", stausText: LocaleStrings.cancel_refund },
    { status: "cancelrefunded", stausText: LocaleStrings.cancel_refunded },
    {
      status: "cancelrefundrejected",
      stausText: LocaleStrings.cancel_refundrejected,
    },
    {
      status: "cancelreturn",
      stausText: LocaleStrings.cancel_return,
    },
    {
      status: "cancelledrefundprocessing",
      stausText: LocaleStrings.refund_processing,
    },
    {
      status: "returnrefundprocessing",
      stausText: LocaleStrings.refund_processing,
    },
    {
      status: "pending",
      stausText: LocaleStrings.payment_pending,
    },
  ];

  const ratingCriteria = [
    "replace",
    "reship",
    "returnType",
    "delivered",
    "refund",
    "initiatepickup",
  ];

  useEffect(() => {
    if (isLoggedIn) {
      const id = props.match.params.id;
      window.scrollTo(0, 0);
      props.fetchOrderDetails(session, id, (res) => {
        setIsChanged(false);
      });
    } else {
      history.push("/login");
    }
  }, [isChanged]);

  const redirectOrder = (orderdetailsid) => {
    setIsChanged(true);
    history.push("/order-details/" + orderdetailsid);
  };

  const getPastDate = (days, orderDate) => {
    let nextDate = moment(orderDate, DATE_TIME_FORMAT_WITH_SECOND).add(
      days,
      "days"
    );

    if (moment().isSameOrBefore(nextDate)) {
      return true;
    }

    return false;
  };

  const checkReturnAndRefundPolicy = () => {
    var createdate = "";
    _.map(orderDetails.statuses, (item, index) => {
      let creatdat = item.createdat;
      if (item.status == "placed") {
        // delivered
        createdate = creatdat;
      }
    });
    //console.log("createdate", createdate);
    if (orderDetails.return_time) {
      let return_time = orderDetails.return_time;

      if (return_time == "no_return") {
        return false;
      }
      if (return_time == "14days") {
        return getPastDate(14, createdate);
      }
      if (return_time == "30days") {
        return getPastDate(30, createdate);
      }
      if (return_time == "45days") {
        return getPastDate(45, createdate);
      }
    }
    return true;
  };

  const statusRender = () => {
    if (orderDetails.currentstatus.status == "cancelled") {
      let item = orderDetails.statuses[0];

      let statusText = _.filter(orderStatus, function (obj) {
        return obj.status == item.status;
      });
      return (
        <li
          className={
            localLanguage == "en"
              ? `progress__item progress__item--cancelled`
              : `progressright__item progressright__item--cancelled`
          }
        >
          <div
            className={
              localLanguage == "en" ? "progress__title" : "progressright__title"
            }
          >
            {" "}
            {LocaleStrings.order_cancel}
          </div>
          <div
            className={
              localLanguage == "en" ? "progress__info" : "progressright__info"
            }
          >
            {converDateIntoLocal(item.createdat).format(BIG_DATE_FORMAT)}
          </div>
        </li>
      );
    } else {
      return _.map(orderDetails.statuses, (item, index) => {
        let statusText = _.filter(orderStatus, function (obj) {
          return obj.status == item.status;
        });
        //console.log("item.status", item.status);
        //console.log("statusText", statusText);
        let selClass =
          parseInt(orderDetails.currentstatus.id) > parseInt(item.id)
            ? localLanguage == "en"
              ? "progress__item--completed"
              : "progressright__item--completed"
            : parseInt(orderDetails.currentstatus.id) == parseInt(item.id)
            ? localLanguage == "en"
              ? "progress__item--active"
              : "progressright__item--active"
            : "";
        return (
          <li
            className={
              localLanguage == "en"
                ? `progress__item ${selClass}`
                : `progressright__item ${selClass}`
            }
          >
            <div
              className={
                localLanguage == "en"
                  ? "progress__title"
                  : "progressright__title"
              }
            >
              {statusText[0].stausText}
              {item.status == "refunded"
                ? "(" + CURRENCY + orderDetails.currentstatus.refundamount + ")"
                : ""}
            </div>
            <div
              className={
                localLanguage == "en" ? "progress__info" : "progressright__info"
              }
            >
              {converDateIntoLocal(item.createdat).format(BIG_DATE_FORMAT)}
            </div>
          </li>
        );
      });
    }
  };

  const renderRating = () => {
    let ratingHtml = "";
    if (orderDetails.reviewadded & (orderDetails.reviewadded == 1)) {
      if (orderDetails.productrating && orderDetails.productrating > 0) {
        ratingHtml = (
          <StarRatings
            rating={orderDetails.productrating}
            starRatedColor="#009CDE"
            numberOfStars={5}
            name="rating"
            starDimension="20px"
            starSpacing="1px"
          />
        );
      }
    } else {
      ratingHtml = (
        <StarRatings
          rating={orderDetails.productrating}
          starEmptyColor="#D5DBE7"
          starHoverColor="#D5DBE7"
          numberOfStars={5}
          name="rating"
          starDimension="20px"
          starSpacing="1px"
          changeRating={() => postrateProduct()}
          isSelectable={false}
        />
      );
    }
    return ratingHtml;
  };

  const postrateProduct = () => {
    props.isRateProductModalOpen({ show: true });
  };

  const cancelOrder = () => {
    confirmAlert({
      title: LocaleStrings.btn_cancel,
      message: LocaleStrings.confirm_cancel,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            props.isCancelModalOpen(true);
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  };
  const reOrder = () => {
    //console.log("orderDetails", orderDetails);
    setOriginate("addtocart");
    let productid = orderDetails.productid;
    setProductId(productid);

    props.fetchProductByID(props.session, productid, (res) => {
      if (res.success == 1) {
        if (res.products && res.products.id) {
          let selectedProduct = res.products;
          setSelectedProduct(selectedProduct);
          setVariantId(selectedProduct.variantid);

          if (
            selectedProduct.all_variants &&
            selectedProduct.all_variants.length > 0
          ) {
            let allVariants = [];
            _.map(selectedProduct.all_variants, (obj, index) => {
              obj.selected = obj.id == selectedProduct.variantid ? true : false;
              allVariants.push(obj);
            });
            setAllVariants(allVariants);
          }

          let selectedVariant = _.filter(
            selectedProduct.all_variants,
            function (o) {
              return o.id == selectedProduct.variantid;
            }
          );
          if (selectedVariant && selectedVariant.length > 0) {
            //console.log("selectedVariant", selectedVariant);
            setSelectedVariant(selectedVariant);
          }
          if (
            selectedProduct.product_variants &&
            selectedProduct.product_variants.length > 0
          ) {
            let productVariants = [];
            _.map(selectedProduct.product_variants, (obj, index) => {
              let variantnames = [];
              if (obj.variantnames && obj.variantnames.length > 0) {
                _.map(obj.variantnames, (data, index) => {
                  data.selected =
                    selectedVariant &&
                    selectedVariant.length > 0 &&
                    selectedVariant[0].refkey.indexOf(data.refkey) !== -1
                      ? true
                      : false;
                  variantnames.push(data);
                });
              }
              obj.variantnames = variantnames;
              productVariants.push(obj);
            });
            setProductVariants(productVariants);
          }
          props.openSliderPop(true);
        }
      }
    });
  };
  const returnOrder = () => {
    confirmAlert({
      title: LocaleStrings.btn_replace,
      message: LocaleStrings.confirm_replace,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            let retstatus = "replace";
            setSelectedType(retstatus);
            props.fetchReplaceVariant(
              session,
              orderDetails.orderdetailsid,
              (res) => {
                if (res.success == 1 && res.data) {
                  // console.log("res.data", res.data);
                  // console.log("variantid", orderDetails.variantid);

                  let selectedVariant = [];
                  if (
                    res.data.all_variants &&
                    res.data.all_variants.length > 0
                  ) {
                    //console.log("all_variants", res.data.all_variants);
                    selectedVariant = _.filter(
                      res.data.all_variants,
                      function (o) {
                        return o.id == orderDetails.variantid;
                      }
                    );
                    //console.log("selectedVariant", selectedVariant);
                    rsetSelectedVariant(selectedVariant);
                  }

                  let counter = 0;
                  if (
                    res.data.all_variants &&
                    res.data.all_variants.length > 0
                  ) {
                    let allVariants = [];
                    _.map(res.data.all_variants, (obj, index) => {
                      obj.selected =
                        obj.id == orderDetails.variantid ? true : false;
                      allVariants.push(obj);
                    });

                    rsetAllVariants(allVariants);

                    let hasPayRef = [];
                    hasPayRef = _.filter(res.data.all_variants, function (obj) {
                      return obj.pay || obj.refund;
                    });
                    //console.log("hasPayRef", hasPayRef);
                    if (hasPayRef.length > 0) {
                      counter++;
                    }
                  }

                  if (counter > 0) {
                    setValidationVar(true);
                  }

                  if (
                    res.data.product_variants &&
                    res.data.product_variants.length > 0
                  ) {
                    //console.log("if");
                    let productVariants = [];
                    _.map(res.data.product_variants, (obj, index) => {
                      let variantnames = [];
                      if (obj.variantnames && obj.variantnames.length > 0) {
                        //console.log("variantnames", obj.variantnames);
                        _.map(obj.variantnames, (data, index) => {
                          data.selected =
                            selectedVariant &&
                            selectedVariant.length > 0 &&
                            selectedVariant[0].refkey.indexOf(data.refkey) !==
                              -1
                              ? true
                              : false;
                          variantnames.push(data);
                        });
                      }
                      //console.log("variantnames", variantnames);
                      obj.variantnames = variantnames;
                      productVariants.push(obj);
                    });
                    //console.log("productVariants", productVariants);
                    rsetProductVariants(productVariants);
                  }

                  if (
                    selectedVariant &&
                    selectedVariant.length > 0 &&
                    selectedVariant[0].media &&
                    selectedVariant[0].media.length > 0
                  ) {
                    let variantImageId = selectedVariant[0].media[0].id;
                    rsetVariantImageId(variantImageId);
                  }

                  props.isReturnReplaceOpen(true);
                }
              }
            );
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  };

  const returnOrderOnly = () => {
    confirmAlert({
      title: LocaleStrings.btn_return,
      message: LocaleStrings.confirm_refund,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            props.isReturnReplaceOpen(true);
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  };

  const refundOrder = () => {
    confirmAlert({
      title: LocaleStrings.btn_return_replace,
      message: LocaleStrings.confirm_return_replace,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            let retstatus =
              orderDetails.refund_type && orderDetails.refund_type == "1"
                ? "return"
                : "replace";
            setSelectedType(retstatus);
            props.fetchReplaceVariant(
              session,
              orderDetails.orderdetailsid,
              (res) => {
                if (res.success == 1 && res.data) {
                  let counter = 0;
                  if (
                    res.data.all_variants &&
                    res.data.all_variants.length > 0
                  ) {
                    let allVariants = [];
                    _.map(res.data.all_variants, (obj, index) => {
                      obj.selected =
                        obj.id == orderDetails.variantid ? true : false;
                      allVariants.push(obj);
                    });
                    //console.log("allVariants", allVariants);
                    rsetAllVariants(allVariants);

                    let hasPayRef = [];
                    hasPayRef = _.filter(res.data.all_variants, function (obj) {
                      return obj.pay || obj.refund;
                    });
                    if (hasPayRef.length > 0) {
                      counter++;
                    }
                  }

                  if (counter > 0) {
                    setValidationVar(true);
                  }

                  let selectedVariant = [];
                  if (
                    res.data.all_variants &&
                    res.data.all_variants.length > 0
                  ) {
                    selectedVariant = _.filter(
                      res.data.all_variants,
                      function (o) {
                        return o.id == orderDetails.variantid;
                      }
                    );
                    //console.log("selectedVariant", selectedVariant);
                    rsetSelectedVariant(selectedVariant);
                  }

                  if (
                    res.data.product_variants &&
                    res.data.product_variants.length > 0
                  ) {
                    //console.log("if");
                    let productVariants = [];
                    _.map(res.data.product_variants, (obj, index) => {
                      let variantnames = [];
                      if (obj.variantnames && obj.variantnames.length > 0) {
                        //console.log("variantnames", obj.variantnames);
                        _.map(obj.variantnames, (data, index) => {
                          data.selected =
                            selectedVariant &&
                            selectedVariant.length > 0 &&
                            selectedVariant[0].refkey.indexOf(data.refkey) !==
                              -1
                              ? true
                              : false;
                          variantnames.push(data);
                        });
                      }
                      //console.log("variantnames", variantnames);
                      obj.variantnames = variantnames;
                      productVariants.push(obj);
                    });
                    // console.log("productVariants", productVariants);
                    rsetProductVariants(productVariants);
                  }

                  if (
                    selectedVariant &&
                    selectedVariant.length > 0 &&
                    selectedVariant[0].media &&
                    selectedVariant[0].media.length > 0
                  ) {
                    let variantImageId = selectedVariant[0].media[0].id;
                    rsetVariantImageId(variantImageId);
                  }
                }
              }
            );
            props.isReturnReplaceOpen(true);
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  };

  const renderProductVariant = (variantnames, pos) => {
    if (variantnames && variantnames.length > 0) {
      return _.map(variantnames, (vari, index) => {
        return (
          <div
            onClick={
              vari.selected
                ? null
                : () => makeVariantSelection(vari.refkey, pos)
            }
            className={classNames(
              vari.selected
                ? "border-variantSel text-vendorLink"
                : "border-variant text-cardfooter ",
              "p-1 border rounded text-xs flex items-center justify-center cursor-pointer"
            )}
            key={index}
          >
            {ProductTextLocalized(vari.name_en, vari.name_ar, localLanguage)}
          </div>
        );
      });
    }
  };

  const makeVariantSelection = (refkey, pos) => {
    if (
      selectedProduct.product_variants &&
      selectedProduct.product_variants.length > 0
    ) {
      let productVariants = [];
      let selectedVariantKeys = [];
      _.map(selectedProduct.product_variants, (obj, row) => {
        let variantnames = [];
        if (obj.variantnames && obj.variantnames.length > 0) {
          _.map(obj.variantnames, (data, index) => {
            if (pos == row) {
              data.selected = data.refkey == refkey ? true : false;
              if (data.selected) {
                selectedVariantKeys.push(refkey);
              }
            } else {
              data.selected = data.selected;
              if (data.selected) {
                selectedVariantKeys.push(data.refkey);
              }
            }

            variantnames.push(data);
          });
        }

        obj.variantnames = variantnames;
        productVariants.push(obj);
      });
      setProductVariants(productVariants);

      let selectedVariantKey = selectedVariantKeys.join("-");
      //console.log("selectedVariantKey", selectedVariantKey);
      if (
        selectedProduct.all_variants &&
        selectedProduct.all_variants.length > 0
      ) {
        let allVariants = [];

        _.map(selectedProduct.all_variants, (obj, index) => {
          obj.selected = obj.refkey == selectedVariantKey ? true : false;
          allVariants.push(obj);
        });
        setAllVariants(allVariants);
        let selectedVariant = _.filter(allVariants, function (o) {
          return o.selected;
        });
        //console.log("selectedVariant", selectedVariant);
        if (selectedVariant && selectedVariant.length > 0) {
          setSelectedVariant(selectedVariant);
          setVariantId(selectedVariant[0].id);
        }
      }
    }
  };

  const updateQuantity = (quantity) => {
    if (
      selectedProduct.all_variants &&
      selectedProduct.all_variants.length > 0
    ) {
      let allVariants = [];

      _.map(selectedProduct.all_variants, (obj, index) => {
        if (obj.id == variantId) {
          obj.quantity = parseInt(obj.quantity) - quantity;
        }
        allVariants.push(obj);
      });
      setAllVariants(allVariants);
      let selectedVariant = _.filter(allVariants, function (o) {
        return o.selected;
      });
      //console.log("selectedVariant", selectedVariant);
      if (selectedVariant && selectedVariant.length > 0) {
        setSelectedVariant(selectedVariant);
      }
    }
  };

  const raiseTicket = () => {
    props.isRaiseTicketModalopen(true);
  };

  const cancelReturn = () => {
    confirmAlert({
      title: LocaleStrings.btn_cancel,
      message: LocaleStrings.confirm_cancel,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            let updateData = {};
            updateData.status = "cancelreturn";
            updateData.orderid = orderDetails.orderid;
            updateData.orderdetailsid = orderDetails.orderdetailsid;

            //console.log("updateData", updateData);
            props.updateOrder(session, updateData, (response) => {
              if (response.success == 1) {
                props.fetchOrderDetails(
                  session,
                  orderDetails.orderdetailsid,
                  (res) => {
                    toast.success(LocaleStrings.cancel_success);
                  }
                );
              } else {
                toast.error(LocaleStrings.common_fail_message);
              }
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  };

  const payNow = (type) => {
    if (orderDetails.order_master_id) {
      setLoading(true);
      setPaymethod(type);
      let values = {};
      values.order_master_id = orderDetails.order_master_id;
      values.payment_method = type;
      values.callfrom = "web";
      props.makePaymentonly(session, values, (response) => {
        if (response.success == 1) {
          if (values.payment_method == "cash") {
            setLoading(false);
            setPaymethod("");
            toast.success(LocaleStrings.order_placed_success);
            history.push("/myorders");
          } else {
            setPaymethod("");
            setLoading(false);
            if (response.checkout_url) {
              window.location.href = response.checkout_url;
            } else {
              toast.error(LocaleStrings.common_fail_message);
            }
          }
        } else {
          toast.error(LocaleStrings.common_fail_message);
        }
      });
    }
  };

  let cancancel = false;
  if (
    !_.isEmpty(orderDetails) &&
    orderDetails.order_details &&
    orderDetails.order_details.relatedorder &&
    orderDetails.order_details.relatedorder.replace_orderdetailsid &&
    orderDetails.order_details.relatedorder.currentstatus == "return" &&
    (orderDetails.currentstatus.status == "placed" ||
      orderDetails.currentstatus.status == "dispatched" ||
      orderDetails.currentstatus.status == "intransit")
  ) {
    cancancel = true;
  }
  let relatedStatus = [];
  if (
    !_.isEmpty(orderDetails) &&
    orderDetails.order_details &&
    orderDetails.order_details.relatedorder &&
    orderDetails.order_details.relatedorder.currentstatus
  ) {
    relatedStatus = _.filter(orderStatus, function (obj) {
      return (
        obj.status == orderDetails.order_details.relatedorder.currentstatus
      );
    });
  }

  let onlyreturn = false;
  if (
    !_.isEmpty(orderDetails) &&
    orderDetails.order_details &&
    orderDetails.order_details.relatedorder &&
    orderDetails.order_details.relatedorder.replace_orderdetailsid
  ) {
    onlyreturn = true;
  }
  // let isCashonDelivery = false;
  // let cashArr = _.filter(orderMasterDetails, function (obj) {
  //   return obj.cashondelivery == "no";
  // });
  // isCashonDelivery = cashArr.length > 0 ? false : true;
  //console.log("orderDetails", orderDetails);
  //console.log("checkReturnAndRefundPolicy", checkReturnAndRefundPolicy());
  return (
    <>
      {!_.isEmpty(orderDetails) ? (
        <>
          <div className="mt-2 mb-2 custom_container flex items-center">
            <a
              className="text-link cursor-pointer"
              onClick={() => history.push("/myorders")}
            >
              {LocaleStrings.my_orders}
            </a>
            <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
            {LocaleStrings.order_details}
          </div>
          <div className="bg-newarrival py-25">
            <div className="block md:flex md:gap-6 custom_container">
              {deviceSize.isMobile && orderDetails.invoice_path ? (
                <div className="fixed inset-x-0 bottom-0 py-5 px-10 bg-white z-50 ">
                  <a
                    className="w-full h-41 text-white bg-btn text-sm md:text-base font-bold rounded btn-heavy inline-flex items-center justify-center"
                    href={`${FILES_URL}/${orderDetails.invoice_path}?api_key=${APP_API_KEY}`}
                    target="_blank"
                    download
                  >
                    <span>{LocaleStrings.download_invoice}</span>
                  </a>
                </div>
              ) : (
                ""
              )}

              <div className="w-full md:w-cartleft mb-4 md:mr-4 ">
                <div className="  bg-white rounded mb-4 pb-4">
                  {deviceSize.isMobile ? (
                    <div className=" py-md px-md border-b border-primary">
                      <div className="font-medium text-sm text-primary">
                        {LocaleStrings.order_id}&nbsp;:&nbsp;
                        {orderDetails.invoice_no}
                      </div>
                    </div>
                  ) : (
                    <div className=" py-md px-md border-b border-primary  grid grid-cols-3 items-center">
                      <div className="font-medium text-sm text-primary col-span-2">
                        {LocaleStrings.order_id}&nbsp;:&nbsp;
                        {orderDetails.invoice_no}
                      </div>

                      <div>
                        {orderDetails.invoice_path ? (
                          <a
                            className="w-full h-41 text-white bg-btn text-sm md:text-base font-bold rounded btn-heavy inline-flex items-center justify-center"
                            href={`${FILES_URL}/${orderDetails.invoice_path}?api_key=${APP_API_KEY}`}
                            download
                            target="_blank"
                          >
                            <span>{LocaleStrings.download_invoice}</span>
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    className={classNames(
                      localLanguage == "en" ? " pl-15" : " pr-15",
                      "  relative  flex  pt-4 pb-4 gap-4"
                    )}
                  >
                    {orderDetails.currentstatus &&
                    orderDetails.currentstatus.status &&
                    orderDetails.currentstatus.status == "pending" ? (
                      <div className="absolute -rotate-45 text-red-500 text-2xl font-medium transform -left-1.5 ">
                        {LocaleStrings.payment_pending}
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      className={classNames(
                        orderDetails.currentstatus &&
                          orderDetails.currentstatus.status &&
                          orderDetails.currentstatus.status == "pending"
                          ? "opacity-30"
                          : "opacity-100",
                        "  flex items-center justify-center p-5  rounded-md border border-primary w-82 h-82"
                      )}
                    >
                      <a
                        href={`/product-details/${
                          _.kebabCase(orderDetails.productname_en) +
                          "-" +
                          orderDetails.productid +
                          "-" +
                          orderDetails.variantid
                        }`}
                        className="cursor-pointer"
                      >
                        <img
                          className="rounded-md object-cover"
                          src={`${FILES_URL}/vendor/variations/${orderDetails.productimage}?api_key=${APP_API_KEY}`}
                        />
                      </a>
                    </div>
                    <div
                      className={classNames(
                        orderDetails.currentstatus &&
                          orderDetails.currentstatus.status &&
                          orderDetails.currentstatus.status == "pending"
                          ? "opacity-30 "
                          : "opacity-100",
                        "  bg-white  w-4/5"
                      )}
                    >
                      <div className="font-medium text-primary text-sm">
                        <a
                          href={`/product-details/${
                            _.kebabCase(orderDetails.productname_en) +
                            "-" +
                            orderDetails.productid +
                            "-" +
                            orderDetails.variantid
                          }`}
                          className="cursor-pointer"
                        >
                          {ProductTextLocalized(
                            orderDetails.productname_en,
                            orderDetails.productname_ar,
                            localLanguage
                          )}
                        </a>
                      </div>
                      <div className="text-cardfooter text-sm">
                        {LocaleStrings.seller}&nbsp;:&nbsp;
                        {orderDetails?.seller?.username}
                      </div>
                      <div className="text-cardfooter text-sm">
                        {LocaleStrings.sku}&nbsp;:&nbsp;
                        {orderDetails?.sku}
                      </div>
                      <div className="text-cardfooter text-sm">
                        {LocaleStrings.quantity}&nbsp;:&nbsp;
                        {orderDetails?.quantity}
                      </div>
                      <div className="text-primary text-sm font-medium">
                        {CURRENCY}
                        {orderDetails?.originalprice}
                      </div>
                      <div className="mt-4">
                        {orderDetails.currentstatus &&
                        orderDetails.currentstatus.status &&
                        orderDetails.currentstatus.status == "pending" ? (
                          ""
                        ) : (
                          <button
                            className="h-8 pl-sm pr-sm bg-newarrival border border-secondary text-primary rounded font-normal text-xs w-11/12 md:w-1/2 lg:w-5/12"
                            onClick={() => raiseTicket()}
                          >
                            {LocaleStrings.raise_a_ticket}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex pl-15 pt-4 pb-4 justify-center">
                    {orderDetails.currentstatus &&
                    orderDetails.currentstatus.status &&
                    orderDetails.currentstatus.status == "pending" ? (
                      <div className="flex w-4/5 pl-4">
                        <div className="w-1/2">
                          <button
                            className="h-8 pl-sm pr-sm bg-btn text-white font-medium text-sm cursor-pointer rounded w-full lg:w-3/4 flex items-center justify-center"
                            onClick={() => payNow("card")}
                          >
                            {loading && paymethod == "card" ? (
                              <div>
                                <svg
                                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    stroke-width="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="text-sm">
                              {LocaleStrings.cart_payment_card}
                            </div>
                          </button>
                        </div>
                        <div className="w-1/2 ml-3">
                          <button
                            className="h-8 pl-sm pr-sm bg-btn text-white font-medium text-sm cursor-pointer rounded w-full lg:w-3/4 flex items-center justify-center"
                            onClick={() => payNow("cash")}
                          >
                            {loading && paymethod == "cash" ? (
                              <div>
                                <svg
                                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    stroke-width="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="text-sm">
                              {LocaleStrings.cart_payment_cash}
                            </div>
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="grid grid-cols-3 pl-15 pb-4">
                    <div className="col-span-2 ">
                      <div
                        className={classNames(
                          localLanguage == "en" ? "pl-4 " : " pr-8",
                          "  text-primary text-11 font-medium"
                        )}
                      >
                        {LocaleStrings.order_status}
                      </div>
                      <div className="progressContainer">
                        <ul
                          className={
                            localLanguage == "en" ? "progress" : "progressright"
                          }
                        >
                          {statusRender()}
                        </ul>
                      </div>
                    </div>
                    <div className="flex items-end">
                      <img src={FlightIcon} className="w-full" />
                    </div>
                  </div>
                  {orderDetails.currentstatus &&
                  orderDetails.currentstatus.status &&
                  orderDetails.currentstatus.status == "cancelled" ? (
                    <div className="px-15 pb-4 border-t border-primary">
                      <div className="text-primary text-sm font-medium pt-invitation4">
                        {LocaleStrings.cancel_reason}
                      </div>
                      <div className="text-primary text-sm font-normal">
                        {orderDetails.currentstatus.cancelreason}
                      </div>
                      <div className="text-cardfooter text-sm pt-5">
                        {LocaleStrings.cancel_note}
                      </div>
                    </div>
                  ) : _.filter(orderDetails.statuses, function (obj) {
                      return obj.status == "cancelled";
                    }).length > 0 ? (
                    <div className="px-15 pb-4 border-t border-primary">
                      <div className="text-primary text-sm font-medium pt-invitation4">
                        {LocaleStrings.cancel_reason}
                      </div>
                      <div className="text-primary text-sm font-normal">
                        {
                          _.filter(orderDetails.statuses, function (obj) {
                            return obj.status == "cancelled";
                          })[0]["cancelreason"]
                        }
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {orderDetails.currentstatus &&
                  orderDetails.currentstatus.status &&
                  orderDetails.currentstatus.status == "refundrejected" ? (
                    <div className="px-15 pb-4 border-t border-primary">
                      <div className="text-primary text-sm font-medium pt-invitation4">
                        {LocaleStrings.reject_reason}
                      </div>
                      <div className="text-primary text-sm font-normal">
                        {orderDetails.currentstatus.refundrejectreason}
                      </div>
                    </div>
                  ) : _.filter(orderDetails.statuses, function (obj) {
                      return obj.status == "refundrejected";
                    }).length > 0 ? (
                    <div className="px-15 pb-4 border-t border-primary">
                      <div className="text-primary text-sm font-medium pt-invitation4">
                        {LocaleStrings.reject_reason}
                      </div>
                      <div className="text-primary text-sm font-normal">
                        {
                          _.filter(orderDetails.statuses, function (obj) {
                            return obj.status == "refundrejected";
                          })[0]["refundrejectreason"]
                        }
                      </div>
                    </div>
                  ) : _.filter(orderDetails.statuses, function (obj) {
                      return obj.status == "cancelrefundrejected";
                    }).length > 0 ? (
                    <div className="px-15 pb-4 border-t border-primary">
                      <div className="text-primary text-sm font-medium pt-invitation4">
                        {LocaleStrings.refund_cancel_reason}
                      </div>
                      <div className="text-primary text-sm font-normal">
                        {
                          _.filter(orderDetails.statuses, function (obj) {
                            return obj.status == "cancelrefundrejected";
                          })[0]["refundrejectreason"]
                        }
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {orderDetails.currentstatus &&
                  orderDetails.currentstatus.status &&
                  (orderDetails.currentstatus.status == "replace" ||
                    orderDetails.currentstatus.status == "return") ? (
                    <div className="px-15 pb-4 border-t border-primary">
                      <div className="text-primary text-sm font-medium pt-invitation4">
                        {LocaleStrings.return_reason}
                      </div>
                      <div className="text-primary text-sm font-normal">
                        {orderDetails.currentstatus.returnreason}
                      </div>
                    </div>
                  ) : _.filter(orderDetails.statuses, function (obj) {
                      return obj.status == "replace" || obj.status == "return";
                    }).length > 0 ? (
                    <div className="px-15 pb-4 border-t border-primary">
                      <div className="text-primary text-sm font-medium pt-invitation4">
                        {LocaleStrings.return_reason}
                      </div>
                      <div className="text-primary text-sm font-normal">
                        {
                          _.filter(orderDetails.statuses, function (obj) {
                            return (
                              obj.status == "replace" || obj.status == "return"
                            );
                          })[0]["returnreason"]
                        }
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {orderDetails.order_details &&
                  orderDetails.order_details.relatedorder &&
                  orderDetails.order_details.relatedorder
                    .replace_orderdetailsid ? (
                    <div className="px-15 mb-3">
                      <div className="font-medium text-base text-primary">
                        {orderDetails.order_details.relatedorder.refund_amount >
                        0
                          ? LocaleStrings.exchange_product
                          : LocaleStrings.exchange_order}
                      </div>
                      <div className="font-medium text-sm text-primary">
                        {LocaleStrings.order_id}&nbsp;:&nbsp;
                        <a
                          onClick={() =>
                            redirectOrder(
                              orderDetails.order_details.relatedorder
                                .replace_orderdetailsid
                            )
                          }
                          className="text-link cursor-pointer"
                        >
                          {orderDetails.order_details.relatedorder.invoice_no}
                        </a>
                      </div>
                      <div className="font-medium text-sm text-primary">
                        {LocaleStrings.current_status}&nbsp;:&nbsp;
                        {relatedStatus.length > 0
                          ? relatedStatus[0].stausText
                          : ""}
                      </div>

                      {orderDetails.order_details.relatedorder.refund_amount >
                        0 &&
                      orderDetails.order_details.relatedorder.currentstatus &&
                      orderDetails.order_details.relatedorder.currentstatus !=
                        "cancelreturn" ? (
                        <div className="font-medium text-sm text-primary">
                          {LocaleStrings.replace_refund}&nbsp;:&nbsp;
                          {CURRENCY}
                          {
                            orderDetails.order_details.relatedorder
                              .refund_amount
                          }
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {_.includes(
                    ratingCriteria,
                    orderDetails?.currentstatus?.status
                  ) &&
                  _.filter(orderDetails.statuses, function (obj) {
                    return obj.status == "delivered";
                  }).length > 0 &&
                  orderDetails.saletype != "auction" ? (
                    <div className="px-15 mb-3">
                      <div className="grid grid-cols-2 border-b border-t border-primary py-2 ">
                        <div className="font-medium text-sm text-primary">
                          {LocaleStrings.rate_delivaery}
                        </div>
                        <div className="flex items-center justify-end">
                          {renderRating()}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {cancancel ? (
                    <div className="px-15">
                      <div className="font-medium text-sm text-primary">
                        <button
                          className="h-8 pl-sm pr-sm bg-ask border border-variantSel text-vendorLink rounded font-normal text-xs w-full md:w-1/2"
                          onClick={() => cancelOrder()}
                        >
                          {LocaleStrings.btn_cancel}
                        </button>
                      </div>
                    </div>
                  ) : !orderDetails?.order_details?.relatedorder &&
                    (orderDetails?.currentstatus?.status == "placed" ||
                      orderDetails?.currentstatus?.status == "dispatched" ||
                      orderDetails?.currentstatus?.status == "intransit") ? (
                    <div className="px-15">
                      <div className="font-medium text-sm text-primary">
                        <button
                          className="h-8 pl-sm pr-sm bg-ask border border-variantSel text-vendorLink rounded font-normal text-xs w-full md:w-1/2"
                          onClick={() => cancelOrder()}
                        >
                          {LocaleStrings.btn_cancel}
                        </button>
                      </div>
                    </div>
                  ) : orderDetails?.currentstatus?.status == "return" ||
                    orderDetails?.currentstatus?.status == "replace" ? (
                    <div className="px-15">
                      <div className="font-medium text-sm text-primary">
                        <button
                          className="h-8 pl-sm pr-sm bg-ask border border-variantSel text-vendorLink rounded font-normal text-xs w-full md:w-1/2"
                          onClick={() => cancelReturn()}
                        >
                          {LocaleStrings.btn_cancel}
                        </button>
                      </div>
                    </div>
                  ) : orderDetails?.currentstatus?.status == "delivered" ||
                    orderDetails?.currentstatus?.status == "reshipdelivered" ? (
                    <div className="px-15">
                      <div className="grid grid-cols-2 gap-3 py-2">
                        <div>
                          {orderDetails?.refund_type == "1" &&
                          checkReturnAndRefundPolicy() &&
                          !onlyreturn ? (
                            <button
                              className="h-8 pl-sm pr-sm bg-newarrival border border-secondary text-primary rounded font-normal text-xs w-full md:w-1/2"
                              onClick={() => refundOrder()}
                            >
                              {LocaleStrings.btn_return_replace}
                            </button>
                          ) : (orderDetails?.refund_type == "2" ||
                              orderDetails?.refund_type == "3") &&
                            checkReturnAndRefundPolicy() &&
                            !onlyreturn ? (
                            <button
                              className="h-8 pl-sm pr-sm bg-newarrival border border-secondary text-primary rounded font-normal text-xs w-full md:w-1/2"
                              onClick={() => returnOrder()}
                            >
                              {LocaleStrings.btn_replace}
                            </button>
                          ) : onlyreturn && checkReturnAndRefundPolicy() ? (
                            <button
                              className="h-8 pl-sm pr-sm bg-newarrival border border-secondary text-primary rounded font-normal text-xs w-full md:w-1/2"
                              onClick={() => returnOrderOnly()}
                            >
                              {LocaleStrings.btn_return}
                            </button>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="flex items-center justify-end">
                          <button
                            className="h-8 pl-sm pr-sm bg-ask border border-variantSel text-vendorLink rounded font-normal text-xs w-full md:w-1/2"
                            onClick={() => reOrder()}
                          >
                            {LocaleStrings.btn_reorder}
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="w-full md:w-cartright">
                <div className="bg-white rounded mb-4 px-md py-md">
                  <div className="font-medium text-sm text-primary">
                    {LocaleStrings.deliverto}
                  </div>
                  <div
                    className={classNames(
                      orderDetails.currentstatus &&
                        orderDetails.currentstatus.status &&
                        orderDetails.currentstatus.status == "pending"
                        ? "opacity-30"
                        : "opacity-100",
                      "  flex pb-3.5 pt-1 gap-4"
                    )}
                  >
                    <div className="pr-3.5">
                      <div className="bg-bid rounded-full w-46 h-46 flex items-center justify-center">
                        <img src={DeliverytoIcon} className="w-5" />
                      </div>
                    </div>
                    <div>
                      {!_.isEmpty(
                        orderDetails?.order_details?.delivery_address
                      ) ? (
                        <>
                          <div className="text-primary font-normal text-base">
                            {_.startCase(
                              orderDetails?.order_details?.delivery_address
                                ?.addresstype
                            )}
                            {" : "}
                            {
                              orderDetails?.order_details?.delivery_address
                                ?.username
                            }
                          </div>
                          {orderDetails?.order_details?.delivery_address
                            ?.address ? (
                            <div className="text-cardfooter font-normal text-xs">
                              {
                                orderDetails?.order_details?.delivery_address
                                  ?.address
                              }
                            </div>
                          ) : (
                            ""
                          )}
                          {orderDetails?.order_details?.delivery_address
                            ?.phone ? (
                            <div className="text-cardfooter font-normal text-xs">
                              {LocaleStrings.contact_no +
                                orderDetails?.order_details?.delivery_address
                                  ?.phone}
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded px-md py-md">
                  <div className="font-medium text-sm text-primary">
                    {LocaleStrings.cart_order_summery}
                  </div>
                  <div
                    className={classNames(
                      orderDetails.currentstatus &&
                        orderDetails.currentstatus.status &&
                        orderDetails.currentstatus.status == "pending"
                        ? "opacity-30"
                        : "opacity-100",
                      "  pt-6"
                    )}
                  >
                    {orderDetails?.order_details?.summary?.actionkey &&
                    (orderDetails?.order_details?.summary?.amount_paid > 0 ||
                      orderDetails?.order_details?.summary?.refund_amount >
                        0) ? (
                      <>
                        <div className="grid grid-cols-4 mb-2">
                          <div className="col-span-3 text-primary text-xs font-medium">
                            {LocaleStrings.original_product_price}
                          </div>
                          <div className="text-right text-primary font-medium text-xs">
                            {CURRENCY}
                            {parseFloat(
                              orderDetails?.order_details?.summary
                                ?.previous_order_price
                            ).toFixed(2)}
                          </div>
                        </div>
                        <div className="grid grid-cols-4 mb-2">
                          <div className="col-span-3 text-primary text-xs font-medium">
                            {LocaleStrings.exchange_product}
                          </div>
                          <div className="text-right text-primary font-medium text-xs">
                            {CURRENCY}
                            {parseFloat(
                              orderDetails?.order_details?.summary
                                ?.current_order_price
                            ).toFixed(2)}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="grid grid-cols-4 mb-2">
                        <div className="col-span-3 text-primary text-xs font-medium">
                          {LocaleStrings.cart_product_price}
                        </div>
                        <div className="text-right text-primary font-medium text-xs">
                          {CURRENCY}
                          {orderDetails.order_details &&
                          orderDetails.order_details.relatedorder &&
                          (orderDetails.order_details.relatedorder
                            .amount_paid ||
                            orderDetails.order_details.relatedorder
                              .refund_amount)
                            ? Math.max(
                                orderDetails.order_details.relatedorder
                                  .amount_paid,
                                orderDetails.order_details.relatedorder
                                  .refund_amount
                              )
                            : parseFloat(
                                orderDetails?.order_details?.summary
                                  ?.product_total
                              ).toFixed(2)}
                        </div>
                      </div>
                    )}
                    <div className="grid grid-cols-4 mb-2">
                      <div className="col-span-3 text-primary text-xs font-medium">
                        {LocaleStrings.cart_product_discount}
                      </div>
                      <div className="text-right text-primary font-medium text-xs">
                        - {CURRENCY}
                        {parseFloat(
                          orderDetails?.order_details?.summary?.discount
                        ).toFixed(2)}
                      </div>
                    </div>
                    {orderDetails?.order_details?.summary?.coupon_discount >
                    0 ? (
                      <div className="grid grid-cols-4 mb-2">
                        <div className="col-span-3 text-primary text-xs font-medium">
                          {LocaleStrings.cart_coupon_discount}
                        </div>
                        <div className="text-right text-primary font-medium text-xs">
                          {CURRENCY}
                          {parseFloat(
                            orderDetails?.order_details?.summary
                              ?.coupon_discount
                          ).toFixed(2)}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="grid grid-cols-4 mb-2">
                      <div className="col-span-3 text-primary text-xs font-medium">
                        {LocaleStrings.cart_shipping_fee}
                      </div>
                      <div className="text-right text-primary font-medium text-xs">
                        {CURRENCY}
                        {parseFloat(
                          orderDetails?.order_details?.summary?.shipping
                        ).toFixed(2)}
                      </div>
                    </div>
                    <div className="grid grid-cols-4 pb-2 mb-2 border-b border-primary">
                      <div className="col-span-3 text-primary text-xs font-medium">
                        {LocaleStrings.cart_tax_charges}
                      </div>
                      <div className="text-right text-primary font-medium text-xs">
                        {CURRENCY}
                        {parseFloat(
                          orderDetails?.order_details?.summary?.tax
                        ).toFixed(2)}
                      </div>
                    </div>
                    <div className="grid grid-cols-4">
                      <div className="col-span-3 text-primary text-xs font-medium">
                        {orderDetails?.order_details?.summary?.actionkey &&
                        (orderDetails?.order_details?.summary?.amount_paid >
                          0 ||
                          orderDetails?.order_details?.summary?.refund_amount >
                            0)
                          ? orderDetails?.order_details?.summary?.amount_paid >
                            0
                            ? LocaleStrings.order_details_paid
                            : LocaleStrings.order_details_refund
                          : LocaleStrings.cart_total}
                      </div>
                      <div className="text-right text-primary font-medium text-xs">
                        {CURRENCY}

                        {orderDetails.order_details &&
                        orderDetails.order_details.relatedorder &&
                        (orderDetails.order_details.relatedorder.amount_paid ||
                          orderDetails.order_details.relatedorder.refund_amount)
                          ? Math.max(
                              orderDetails.order_details.relatedorder
                                .amount_paid,
                              orderDetails.order_details.relatedorder
                                .refund_amount
                            )
                          : parseFloat(
                              orderDetails?.order_details?.summary?.grand_total
                            ).toFixed(2)}
                      </div>
                    </div>
                    {orderDetails?.order_details?.summary?.payment_method ? (
                      <div className="grid grid-cols-4 mb-8">
                        <div className="col-span-3 text-primary text-xs font-medium">
                          {LocaleStrings.payment_method}
                        </div>
                        <div className="col-span-2 text-right text-cardfooter font-normal text-10">
                          {orderDetails?.order_details?.summary?.payment_method}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader data={LocaleStrings.loader_message} />
      )}
      <CancelModal />
      {returnReplaceModal ? (
        <ReturnReplace
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          setValidationVar={setValidationVar}
          validationVar={validationVar}
          rproductVariants={rproductVariants}
          rsetProductVariants={rsetProductVariants}
          rallVariants={rallVariants}
          rsetAllVariants={rsetAllVariants}
          rvariantImageId={rvariantImageId}
          rsetVariantImageId={rsetVariantImageId}
          rselectedVariant={rselectedVariant}
          rsetSelectedVariant={rsetSelectedVariant}
          setVariantId={setVariantId}
          variantId={variantId}
        />
      ) : (
        ""
      )}
      {rateProduct.show ? <PostRating /> : ""}
      <RaiseTicketAdd orderid={props.match.params.id} />
      <SlideOver title={""}>
        <ChooseVariant
          productid={productid}
          variantId={variantId}
          allVariants={allVariants}
          productVariants={productVariants}
          selectedProduct={selectedProduct}
          selectedVariant={selectedVariant}
          renderProductVariant={renderProductVariant}
          updateQuantity={updateQuantity}
          originate={originate}
        />
      </SlideOver>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  orderDetails: state.orderDetails,
  deviceSize: state.deviceSize,
  returnReplaceModal: state.returnReplaceModal,
  orderCancelModal: state.orderCancelModal,
  localCurrency: state.localCurrency,
  rateProduct: state.rateProduct,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchOrderDetails,
      isRateProductModalOpen,
      isReturnReplaceOpen,
      isCancelModalOpen,
      fetchProductByID,
      openSliderPop,
      isRaiseTicketModalopen,
      updateOrder,
      fetchReplaceVariant,
      makePaymentonly,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyOrders);
